// Copyright 2017-2024 @polkadot/apps authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { squidClient } from './squidClient.js';
import { SquidService } from './squidService.js';

// Récupérer les endpoints depuis l'environnement global
declare global {
  interface Window {
    __SQUID_ENDPOINTS__?: string[];
  }
}

interface Props {
  children: React.ReactNode;
}

/**
 * Composant qui encapsule l'application avec ApolloProvider
 * et gère l'initialisation du client Apollo
 */
export function ApolloWrapper({ children }: Props): React.ReactElement<Props> {
  const [isReady, setIsReady] = useState(true);

  // Effet pour vérifier si le client Apollo est prêt
  useEffect(() => {
    // Vérifier si le client est prêt
    const checkClient = () => {
      if (squidClient) {
        setIsReady(true);
      }
    };

    // Vérifier immédiatement
    checkClient();

    // Vérifier périodiquement (au cas où le client change)
    const interval = setInterval(checkClient, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Effet pour démarrer la vérification périodique des endpoints
  useEffect(() => {
    // Vérifier si window est défini (pour éviter les erreurs SSR)
    if (typeof window === 'undefined') return;
    
    // Récupérer les endpoints depuis l'environnement global
    const endpoints = window.__SQUID_ENDPOINTS__ || [];
    
    if (endpoints.length > 0) {
      // Démarrer la vérification périodique des endpoints
      const stopCheck = SquidService.startEndpointsCheck(endpoints);
      
      // Nettoyer lors du démontage du composant
      return () => {
        stopCheck();
      };
    }
    
    return undefined;
  }, []);

  // Afficher un message de chargement si le client n'est pas prêt
  if (!isReady) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        Loading Squid client...
      </div>
    );
  }

  // Encapsuler l'application avec ApolloProvider
  return (
    <ApolloProvider client={squidClient}>
      {children}
    </ApolloProvider>
  );
} 