import { gql } from '@apollo/client';

// Build the GraphQL query to get username
export const GET_NAME_QUERY = gql`
  query GetName($id: String!) {
    accountByPk(id: $id) {
      id
      identity {
        name
      }
      wasIdentity {
        next {
          identity {
            name
          }
        }
      }
    }
}
`;

export interface Identity {
  name: string | null;
}

export interface WasIdentity {
  next: {
    identity: Identity | null;
  } | null;
}

export interface AccountByPk {
  id: string;
  identity: Identity | null;
  wasIdentity: WasIdentity[] | null;
}

export interface GetNameResponse {
  accountByPk: AccountByPk | null;
}
