// Copyright 2017-2024 @polkadot/apps authors & contributors
// SPDX-License-Identifier: Apache-2.0

import { ApolloError } from '@apollo/client';
import { gql } from '@apollo/client';
import { BlockNumberCallback, GraphQLResponse, SquidEndpointStatus } from './types.js';
import { squidClient } from './squidClient.js';

const SQUID_POLL_INTERVAL = 3_000; // 3 secondes
const ENDPOINTS_CHECK_INTERVAL = 60_000; // 1 minute

/**
 * Service pour interagir avec les endpoints Squid
 */
export class SquidService {
  // Map pour stocker les hauteurs des endpoints
  private static endpointsStatus: Map<string, SquidEndpointStatus> = new Map();
  
  /**
   * Récupère le statut de tous les endpoints Squid
   * @returns Map des statuts des endpoints
   */
  static getEndpointsStatus(): Map<string, SquidEndpointStatus> {
    return this.endpointsStatus;
  }
  
  /**
   * Démarre la vérification périodique des endpoints Squid
   * @param endpoints Liste des endpoints à vérifier
   * @returns Fonction pour arrêter la vérification
   */
  static startEndpointsCheck(endpoints: string[]): () => void {
    let isActive = true;
    
    // Si la liste est vide, retourner une fonction vide
    if (!endpoints || endpoints.length === 0) {
      console.warn('Empty endpoints list provided to startEndpointsCheck');
      return () => { /* noop */ };
    }
    
    const checkEndpoints = async () => {
      if (!isActive) return;
      
      for (const endpoint of endpoints) {
        try {
          // Convertir l'URL WebSocket en URL HTTP
          const httpEndpoint = endpoint.replace('wss://', 'https://').replace('ws://', 'http://');
          const height = await this.checkSquidSync(httpEndpoint);
          
          this.endpointsStatus.set(endpoint, {
            url: endpoint,
            height: height,
            lastChecked: new Date(),
            status: height !== null ? 'online' : 'offline'
          });
        } catch (error) {
          console.error(`Error checking endpoint ${endpoint}:`, error);
          this.endpointsStatus.set(endpoint, {
            url: endpoint,
            height: null,
            lastChecked: new Date(),
            status: 'error'
          });
        }
      }
    };
    
    // Première vérification immédiate
    checkEndpoints();
    
    // Puis vérification périodique
    const interval = setInterval(checkEndpoints, ENDPOINTS_CHECK_INTERVAL);
    
    // Fonction pour arrêter la vérification
    return () => {
      isActive = false;
      clearInterval(interval);
    };
  }

  /**
   * Vérifie la synchronisation d'un endpoint Squid HTTP
   * @param endpoint URL de l'endpoint HTTP Squid
   * @returns La hauteur du bloc ou null en cas d'erreur
   */
  static async checkSquidSync(endpoint: string): Promise<number | null> {
    try {
      if (!endpoint.startsWith('http')) {
        console.error(`Invalid HTTP URL: ${endpoint}`);
        return null;
      }
      return this.fetchSquidHeight(endpoint);
    } catch (error) {
      console.error(`Error checking Squid sync for ${endpoint}:`, error);
      return null;
    }
  }

  /**
   * Récupère la hauteur du bloc depuis un endpoint Squid HTTP
   * @param endpoint URL de l'endpoint HTTP Squid
   * @returns La hauteur du bloc ou null en cas d'erreur
   */
  private static async fetchSquidHeight(endpoint: string): Promise<number | null> {
    try {
      const query = `
        query {
          block(limit: 1, orderBy: {height: DESC}) {
            height
          }
        }
      `;

      const response = await fetch(endpoint, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Cache-Control": "no-cache"
        },
        cache: "no-store",
        body: JSON.stringify({ query }),
      });

      if (!response.ok) {
        console.warn(`Failed to fetch from ${endpoint}: ${response.status} ${response.statusText}`);
        return null;
      }

      const result = await response.json() as GraphQLResponse;
      const height = result.data?.block?.[0]?.height;
      
      return height ?? null;
    } catch (error) {
      console.error(`Error fetching from ${endpoint}:`, error);
      return null;
    }
  }

  /**
   * Récupère la hauteur du bloc depuis le client WebSocket Squid
   * @returns La hauteur du bloc ou null en cas d'erreur
   */
  static async getBlockHeight(): Promise<number | null> {
    try {
      // Vérifier que le client est disponible
      if (!squidClient) {
        console.error('Squid client not initialized');
        return null;
      }
      
      const GET_BLOCK_HEIGHT = gql`
        query {
          block(limit: 1, orderBy: {height: DESC}) {
            height
          }
        }
      `;

      const result = await squidClient.query({
        query: GET_BLOCK_HEIGHT,
        fetchPolicy: 'network-only' // Forcer une requête réseau à chaque fois
      });

      return result.data?.block?.[0]?.height ?? null;
    } catch (error) {
      // Gérer spécifiquement les erreurs Apollo
      if (error instanceof ApolloError) {
        console.error('Apollo error fetching block height:', error.message, error.networkError);
      } else {
        console.error('Error fetching block height from Squid:', error);
      }
      return null;
    }
  }

  /**
   * S'abonne aux mises à jour de blocs Squid via polling
   * @param callback Fonction appelée à chaque mise à jour
   * @param chainHeight Hauteur actuelle de la chaîne pour comparer (optionnel)
   * @returns Fonction pour annuler l'abonnement
   */
  static subscribeToBlocks(callback: BlockNumberCallback, chainHeight?: number): () => void {
    let isActive = true;

    const pollHeight = async () => {
      if (!isActive) return;
      
      try {
        const height = await this.getBlockHeight();
        
        if (height !== null) {
          const status = chainHeight && (chainHeight - height > 2) 
            ? 'desync' 
            : 'sync';
            
          callback({ 
            status,
            blockNumber: height 
          });
        } else {
          callback({
            status: 'error',
            blockNumber: null
          });
        }
      } catch (error) {
        console.error('Error polling Squid block height:', error);
        callback({
          status: 'error',
          blockNumber: null
        });
      }
    };

    // Première requête immédiate
    pollHeight();
    
    // Puis polling régulier
    const interval = setInterval(pollHeight, SQUID_POLL_INTERVAL);
    
    // Fonction pour annuler l'abonnement
    return () => {
      isActive = false;
      clearInterval(interval);
    };
  }
} 