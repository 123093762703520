// Copyright 2017-2024 @polkadot/apps authors & contributors
// SPDX-License-Identifier: Apache-2.0

import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { SquidEndpoint } from './types.js';

// Déclarer l'extension de Window pour les endpoints Squid
declare global {
  interface Window {
    __SQUID_ENDPOINTS__?: string[];
  }
}

// État du client
let currentClient: ApolloClient<NormalizedCacheObject> | null = null;
let currentEndpointIndex = 0;
let ENDPOINTS: string[] = [];
let ENDPOINTS_HEIGHTS: Map<string, number> = new Map();

// Endpoints de secours en cas d'échec de récupération
const FALLBACK_ENDPOINTS = [
  "https://squid.gdev.coinduf.eu/v1/graphql",
  "https://squid.gdev.gyroi.de/v1/graphql",
  "https://duniter-v2-vjrj-squid.comunes.net/v1/graphql",
  "https://gdev-squid.axiom-team.fr/v1/graphql",
  "https://gdev-indexer.p2p.legal/v1/graphql",
  "https://gdev.1000i100.fr/indexer/v1/graphql",
  "https://squid-hasura.gdev.brussels.ovh/v1/graphql",
  "https://squid-hasura.gdev.de.brussels.ovh/v1/graphql"
];

// Créer un client initial avec les endpoints de secours
// pour éviter les erreurs lors de l'initialisation de l'application
const createInitialClient = () => {
  // Utiliser les 3 premiers endpoints de la liste complète
  const initialEndpoints = FALLBACK_ENDPOINTS.slice(0, 3);
  
  ENDPOINTS = initialEndpoints;
  
  // Exposer les endpoints à l'environnement global
  if (typeof window !== 'undefined') {
    window.__SQUID_ENDPOINTS__ = initialEndpoints;
  }
  
  // Convertir l'URL HTTP en URL WebSocket pour le client GraphQL
  const wsUrl = initialEndpoints[0].replace('https://', 'wss://');
  
  return createNewClient(wsUrl);
};

// URL du fichier JSON contenant les endpoints
const ENDPOINTS_JSON_URL = 'https://git.duniter.org/nodes/networks/-/raw/master/gdev.json';

// Fonction pour récupérer les endpoints depuis le fichier JSON
const fetchEndpoints = async (): Promise<string[]> => {
  try {
    console.log(`Fetching endpoints from ${ENDPOINTS_JSON_URL}`);
    
    // Essayer d'abord avec le mode cors standard
    try {
      const response = await fetch(ENDPOINTS_JSON_URL, {
        headers: { 
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Cache-Control": "no-cache"
        },
        cache: "no-store",
      });
      
      if (response.ok) {
        const data = await response.json();
        return processEndpointsData(data);
      }
      
      console.warn(`Standard fetch failed: ${response.status} ${response.statusText}, trying with JSONP approach`);
    } catch (corsError) {
      console.warn('CORS error, trying alternative approach:', corsError);
    }
    
    // Si la requête standard échoue, utiliser une approche alternative
    // Créer un script pour charger le JSON via JSONP ou utiliser les fallbacks
    return getFallbackEndpoints();
  } catch (error) {
    console.error('Error fetching Squid endpoints:', error);
    return getFallbackEndpoints();
  }
};

// Fonction pour traiter les données des endpoints
const processEndpointsData = (data: any[]): string[] => {
  // Extraire les URLs des endpoints Squid
  const squidEndpoints = data
    .filter((endpoint: SquidEndpoint) => 
      endpoint.url && 
      (
        endpoint.url.startsWith('wss://') || 
        endpoint.url.startsWith('ws://') ||
        endpoint.url.startsWith('https://') ||
        endpoint.url.startsWith('http://')
      ) &&
      (endpoint.enabled !== false) // Inclure seulement les endpoints activés ou sans indication
    )
    .map((endpoint: SquidEndpoint) => endpoint.url);
  
  if (squidEndpoints.length === 0) {
    console.warn('No valid Squid endpoints found in JSON, using fallbacks');
    return getFallbackEndpoints();
  }
  
  console.log(`Found ${squidEndpoints.length} valid Squid endpoints`);
  return squidEndpoints;
};

// Fonction pour récupérer les endpoints de secours
const getFallbackEndpoints = (): string[] => {
  console.log('Using fallback endpoints');
  return FALLBACK_ENDPOINTS;
};

// Fonction pour vérifier la hauteur d'un endpoint Squid HTTP
const checkSquidHeight = async (endpoint: string): Promise<number> => {
  try {
    // Convertir l'URL WebSocket en URL HTTP
    const httpEndpoint = endpoint.replace('wss://', 'https://').replace('ws://', 'http://');
    
    console.log(`Checking height for ${httpEndpoint}`);
    
    const query = `
      query {
        block(limit: 1, orderBy: {height: DESC}) {
          height
        }
      }
    `;

    // Ajouter un timeout pour éviter les requêtes qui prennent trop de temps
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 secondes de timeout
    
    // Essayer d'abord avec le mode standard
    try {
      const response = await fetch(httpEndpoint, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Cache-Control": "no-cache"
        },
        cache: "no-store",
        body: JSON.stringify({ query }),
        signal: controller.signal
      });
      
      clearTimeout(timeoutId);

      if (response.ok) {
        const result = await response.json();
        const height = result.data?.block?.[0]?.height;
        
        if (height === undefined) {
          console.warn(`Invalid response from ${endpoint}`);
          return -1;
        }
        
        console.log(`Endpoint ${endpoint} height: ${height}`);
        return height;
      }
      
      console.warn(`Failed to check height for ${endpoint}: ${response.status} ${response.statusText}`);
    } catch (fetchError: unknown) {
      clearTimeout(timeoutId);
      
      // Si c'est une erreur CORS, on le note spécifiquement
      if (fetchError instanceof TypeError && fetchError.message.includes('CORS')) {
        console.warn(`CORS error for ${endpoint}, endpoint might be valid but not accessible from browser`);
        
        // On retourne 0 pour indiquer que l'endpoint pourrait être valide mais n'est pas accessible
        // Cela permettra de l'utiliser comme fallback si aucun autre endpoint n'est disponible
        return 0;
      }
      
      if (fetchError instanceof Error && fetchError.name === 'AbortError') {
        console.warn(`Timeout checking height for ${endpoint}`);
      } else {
        console.warn(`Fetch error checking height for ${endpoint}:`, fetchError);
      }
    }
    
    return -1;
  } catch (error) {
    console.warn(`Error checking height for ${endpoint}:`, error);
    return -1;
  }
};

// Fonction pour tester la connectivité WebSocket d'un endpoint
const testWebSocketConnection = async (endpoint: string): Promise<boolean> => {
  // Convertir l'URL HTTP en URL WebSocket si nécessaire
  const wsUrl = endpoint.startsWith('http') 
    ? endpoint.replace('http://', 'ws://').replace('https://', 'wss://') 
    : endpoint;
  
  return new Promise<boolean>((resolve) => {
    try {
      console.log(`Testing WebSocket connection to ${wsUrl}`);
      
      // Créer un timeout pour éviter de bloquer trop longtemps
      const timeout = setTimeout(() => {
        console.warn(`WebSocket connection timeout for ${wsUrl}`);
        resolve(false);
      }, 5000);
      
      // Tenter de créer une connexion WebSocket
      const ws = new WebSocket(wsUrl);
      
      // Gérer les événements de la connexion
      ws.onopen = () => {
        console.log(`WebSocket connection successful to ${wsUrl}`);
        clearTimeout(timeout);
        ws.close();
        resolve(true);
      };
      
      ws.onerror = (error) => {
        console.warn(`WebSocket connection error for ${wsUrl}:`, error);
        clearTimeout(timeout);
        resolve(false);
      };
    } catch (error) {
      console.warn(`Error testing WebSocket connection to ${wsUrl}:`, error);
      resolve(false);
    }
  });
};

// Fonction pour trouver l'endpoint avec la hauteur de bloc la plus élevée
const findBestEndpoint = async (endpoints: string[]): Promise<string> => {
  console.log('Checking heights for all endpoints...');
  
  if (endpoints.length === 0) {
    console.warn('Empty endpoints list, using fallback');
    return getFallbackEndpoints()[0];
  }
  
  // Vérifier la hauteur de tous les endpoints en parallèle
  const heightChecks = await Promise.allSettled(
    endpoints.map(async (endpoint) => {
      const height = await checkSquidHeight(endpoint);
      return { endpoint, height };
    })
  );
  
  // Filtrer les résultats réussis et valides (hauteur > 0)
  const validResults = heightChecks
    .filter((result): result is PromiseFulfilledResult<{endpoint: string, height: number}> => 
      result.status === 'fulfilled' && result.value.height > 0
    )
    .map(result => result.value);
  
  // Filtrer les résultats potentiellement valides mais avec CORS (hauteur = 0)
  const corsResults = heightChecks
    .filter((result): result is PromiseFulfilledResult<{endpoint: string, height: number}> => 
      result.status === 'fulfilled' && result.value.height === 0
    )
    .map(result => result.value);
  
  // Mettre à jour la map des hauteurs pour tous les endpoints valides
  validResults.forEach(({ endpoint, height }) => {
    ENDPOINTS_HEIGHTS.set(endpoint, height);
  });
  
  // Trier par hauteur décroissante
  validResults.sort((a, b) => b.height - a.height);
  
  // Afficher les résultats pour le débogage
  validResults.forEach(({ endpoint, height }) => {
    console.log(`Endpoint ${endpoint}: height = ${height}`);
  });
  
  corsResults.forEach(({ endpoint }) => {
    console.log(`Endpoint ${endpoint}: CORS issue, could be valid but not accessible from browser`);
  });
  
  // Si aucun endpoint valide n'est trouvé, essayer d'utiliser un endpoint avec CORS
  if (validResults.length === 0) {
    if (corsResults.length > 0) {
      // Tester la connectivité WebSocket pour les endpoints avec CORS
      for (const { endpoint } of corsResults) {
        const wsConnected = await testWebSocketConnection(endpoint);
        if (wsConnected) {
          console.log(`Found working WebSocket connection for CORS endpoint: ${endpoint}`);
          return endpoint;
        }
      }
      
      console.warn('No valid endpoints found, using an endpoint with CORS issues');
      return corsResults[0].endpoint;
    }
    
    console.warn('No valid endpoints found, using first endpoint');
    return endpoints[0];
  }
  
  // Retourner l'endpoint avec la hauteur la plus élevée
  console.log(`Selected best endpoint: ${validResults[0].endpoint} with height ${validResults[0].height}`);
  return validResults[0].endpoint;
};

const createNewClient = (url: string) => {
  // Convertir l'URL HTTP en URL WebSocket si nécessaire
  const wsUrl = url.startsWith('http') 
    ? url.replace('http://', 'ws://').replace('https://', 'wss://') 
    : url;
  
  console.log(`Creating new client with WebSocket URL: ${wsUrl}`);
  
  const wsLink = new GraphQLWsLink(createClient({
    connectionParams: {
      timeout: 5000
    },
    retryAttempts: 3,
    url: wsUrl
  }));

  return new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
        fetchPolicy: 'network-only'
      },
      watchQuery: {
        errorPolicy: 'all',
        fetchPolicy: 'network-only'
      }
    },
    link: ApolloLink.from([
      onError(({ networkError }) => {
        if (networkError) {
          console.error(`Network Error on endpoint ${currentEndpointIndex}, trying next`);
          switchToNextEndpoint().catch(console.error);
        }
      }),
      wsLink
    ])
  });
};

const switchToNextEndpoint = async () => {
  const nextIndex = currentEndpointIndex + 1;

  currentEndpointIndex = nextIndex >= ENDPOINTS.length ? 0 : nextIndex;

  const nextEndpoint = ENDPOINTS[currentEndpointIndex];
  console.error(`Switching to endpoint ${currentEndpointIndex}: ${nextEndpoint}`);
  
  const newClient = createNewClient(nextEndpoint);

  if (currentClient) {
    currentClient.stop();
    await currentClient.clearStore();
  }

  currentClient = newClient;
  squidClient = newClient;
};

// Initialisation des endpoints et du client
const initializeClient = async () => {
  try {
    // Récupérer la liste des endpoints
    ENDPOINTS = await fetchEndpoints();
    console.log(`Loaded ${ENDPOINTS.length} Squid endpoints`);
    
    // Exposer les endpoints à l'environnement global
    if (typeof window !== 'undefined') {
      window.__SQUID_ENDPOINTS__ = ENDPOINTS;
    }
    
    // Trouver le meilleur endpoint (celui avec la hauteur la plus élevée)
    const bestEndpoint = await findBestEndpoint(ENDPOINTS);
    
    // Trouver l'index du meilleur endpoint
    currentEndpointIndex = ENDPOINTS.indexOf(bestEndpoint);
    if (currentEndpointIndex === -1) {
      currentEndpointIndex = 0;
      console.warn(`Best endpoint not found in list, using first endpoint`);
    }
    
    // Créer un client avec le meilleur endpoint
    const initialClient = createNewClient(ENDPOINTS[currentEndpointIndex]);
    
    if (currentClient) {
      currentClient.stop();
      await currentClient.clearStore();
    }
    
    currentClient = initialClient;
    squidClient = initialClient;
    
    return initialClient;
  } catch (error) {
    console.error('Failed to initialize Squid client:', error);
    
    // Utiliser les endpoints de secours en cas d'échec
    ENDPOINTS = getFallbackEndpoints();
    
    // Exposer les endpoints à l'environnement global
    if (typeof window !== 'undefined') {
      window.__SQUID_ENDPOINTS__ = ENDPOINTS;
    }
    
    const fallbackClient = createNewClient(ENDPOINTS[0]);
    
    if (currentClient) {
      currentClient.stop();
      await currentClient.clearStore();
    }
    
    currentClient = fallbackClient;
    squidClient = fallbackClient;
    
    return fallbackClient;
  }
};

// Exporter une instance du client initialisée avec le premier endpoint
// Cette variable sera mise à jour lors des changements d'endpoint
export let squidClient = createInitialClient();
currentClient = squidClient;

// Initialiser le client avec les endpoints récupérés depuis le fichier JSON
// Cette opération est asynchrone et mettra à jour squidClient une fois terminée
initializeClient().catch(console.error);

// Exporter les fonctions et variables utiles
export const getEndpoints = () => ENDPOINTS;
export const getEndpointsHeights = () => ENDPOINTS_HEIGHTS;
export const getCurrentEndpoint = () => ENDPOINTS[currentEndpointIndex];
